import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { baseUrlDev, baseUrlProd } from './Shared';

export function logOut() {
  localStorage.clear();
  firebase.auth().signOut();
  console.log('AuthService - Log Out - Removed localStorage and logged out');
}


export async function logInRequest(idToken: string) {
  return await fetch(
    `${baseUrlProd}/logIn`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
}
