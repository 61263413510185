import React, { useEffect, useState } from 'react'; // Import the React module
import './Loader.scss';

interface LoaderProps {
  text?: string;
}
export const Loader: React.FC<LoaderProps> = ({ text = '' }) => {
  const [showText, setShowText] = useState<boolean>(false);

  useEffect(() => {
    // Delay the showing of the text to create a smooth effect
    const timer = setTimeout(() => {
      setShowText(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="vertical-center">
      <div className="lds-ring mx-auto d-flex justify-content-center">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="d-flex justify-content-center">
        {text && (
          <p className={`loader-text ${showText ? 'active' : ''}`}>{text}</p>
        )}
      </div>
    </div>
  );
};

export default Loader;
