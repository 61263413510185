import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { Loader } from '../../common/loader/Loader';
import { logInRequest } from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import './Login.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';


import logo from '../../../assets/imgs/logo.png';
import picture0 from '../../../assets/imgs/loginWallpapers/login_wallpaper_0.jpg';
import picture1 from '../../../assets/imgs/loginWallpapers/login_wallpaper_1.jpg';
import picture2 from '../../../assets/imgs/loginWallpapers/login_wallpaper_2.jpg';
import picture3 from '../../../assets/imgs/loginWallpapers/login_wallpaper_3.jpg';
import picture4 from '../../../assets/imgs/loginWallpapers/login_wallpaper_4.jpg';
import picture5 from '../../../assets/imgs/loginWallpapers/login_wallpaper_5.jpg';
import picture6 from '../../../assets/imgs/loginWallpapers/login_wallpaper_6.jpg';
import picture7 from '../../../assets/imgs/loginWallpapers/login_wallpaper_7.jpg';
import picture8 from '../../../assets/imgs/loginWallpapers/login_wallpaper_8.jpg';
import picture9 from '../../../assets/imgs/loginWallpapers/login_wallpaper_9.jpg';
import picture10 from '../../../assets/imgs/loginWallpapers/login_wallpaper_10.jpg';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';

const picturePaths = [
	picture0,
	picture1,
	picture2,
	picture3,
	picture4,
	picture5,
	picture6,
	picture7,
	picture8,
	picture9,
	picture10,
];

const firebaseConfig = {
	apiKey: "AIzaSyDtB7YPi66CdcO4VwJdWvKY-rr8hFTcYuw",
	authDomain: "pompelmoes-5fdb0.firebaseapp.com",
	projectId: "pompelmoes-5fdb0",
	storageBucket: "pompelmoes-5fdb0.appspot.com",
	messagingSenderId: "897194751173",
	appId: "1:897194751173:web:a8317e00764f792d6b2cac",
	measurementId: "G-VBKCCFG1XF"
};

firebase.initializeApp(firebaseConfig);


const handleLogin = async () => {

	// Get the email and password input elements
	const emailInput = document.getElementById('emailInput') as HTMLInputElement;
	const passwordInput = document.getElementById('pwInput') as HTMLInputElement;
	const errorField = document.getElementById('errorFeedback') as HTMLElement;

	// Get the values from the input elements
	const email = emailInput.value;
	const password = passwordInput.value;

	try {
		// Attempt to sign in with email and password
		const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);

		// Log the user in
	} catch (error: any) {
		// Handle login error
		console.error('Login error:', error);

		// Check if the user doesn't exist
		if (error.code === 'auth/user-not-found') {
			alert('User does not exist. ');
			errorField.append("User does not exist")
		}
		errorField.append("Something went wrong with your authentication. Try again.")
	}


}



export const Login = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentPicture, setCurrentPicture] = useState(picturePaths[0]);
	const [error, setError] = useState<String>("")

	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	let timer: NodeJS.Timeout | null = null; // Initialize a timer variable




	useEffect(() => {
		const handleAuthStateChanged = async (user: firebase.User | null) => {
			if (timer) {
				clearTimeout(timer);
			}

			// The timer is only used to debounce the requests, why? -> otherwise 2 or even more requests could be send at once.
			// This solution avoids this problem.
			timer = setTimeout(async () => {
				if (user) {
					try {
						setIsLoading(true);
						const response = await logInRequest(await user.getIdToken());
						if (response.ok) {
							navigate("\dashboard")
						}
						if (!response.ok) {
							console.error('Error logging in: ' + response.statusText);
							setError("User credentials are wrong.")
						}
					} catch (error) {
						console.error('Error generating custom claims:', error);
						// Handle the error (e.g., show an error message to the user)
						// You can also add a return statement here to prevent further execution
						setError("Can not login: "+error);
					} finally {
						setIsLoading(false);
					}
				}
			}, 500); // Adjust the delay as needed
		};

		const getRandomIndex = () => {
			let randomIndex = Math.floor(Math.random() * picturePaths.length);
			while (randomIndex === currentIndex) {
				randomIndex = Math.floor(Math.random() * picturePaths.length);
			}
			setCurrentIndex(randomIndex);
			return randomIndex;
		};

		const interval = setInterval(() => {
			setTimeout(() => {
				setCurrentPicture(picturePaths[getRandomIndex()]);
			}, 1000);
		}, 8000);
		const authStateChangedUnsubscribe = firebase
			.auth()
			.onAuthStateChanged(handleAuthStateChanged);

		// Clean up the event listener when the component unmounts
		return () => {
			authStateChangedUnsubscribe();
			clearInterval(interval);
		};
	}, []);

	return (
		<div>
			{isLoading ? (
				<div style={{ height: '40rem' }}>
					<Loader text="Hold tight, we're signing you in..." />
				</div>
			) : (
				<div className="auth-page-container">
					<div className="form-container">
						<div className="d-flex justify-content-center">
							<img className="logoAuth pr-2" src={logo} alt="vet assist logo" />
						</div>
						<div className="d-flex justify-content-center form_diagnostic">
							<Form.Group className="formfield" >
								Email
								<Form.Control
									type="email" id='emailInput' className="textFieldForm"
								/>
							</Form.Group>
							<Form.Group className="formfield">
								Password
								<Form.Control
									type="password" id='pwInput' className="textFieldForm"
								/>
							</Form.Group>
							<div className="formfield">
								<Button
									className="next_btn"
									id='logInBtn'
									onClick={handleLogin}
								>
									Login &nbsp;&nbsp;
									<AiOutlineArrowRight className="make-diagnostic-icon" />
								</Button>
							</div>
							<div id='errorFeedback' className="formfield errorFeedback">
								{error}
							</div>
						</div>
					</div>
					<div className={`image-container `}>
						<img id="wallpaper" src={currentPicture} alt="Login Wallpaper" />
					</div>
				</div>
			)}
		</div>
	);
};

export default Login;

