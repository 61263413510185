import React from 'react'; // Import the React module
import './Header.scss';
import logo from '../../../assets/imgs/logo.png'

export function Header(prop: any) {

	return (
		<div className="container-left">
			<div className="row">
				<div className="col">
					<img className="logo" src={logo} alt="vet assist logo" />
				</div>
				<div className="col headertext">
					{prop.headertext}
				</div>
			</div>
		</div>
	);
}