import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
  } from 'react';
  import { useLocation } from 'react-router-dom';
  import firebase from 'firebase/compat/app';
  // Define a context
  const DataContext = createContext<any | undefined>(undefined);
  
  interface DataProviderProps {
	children: ReactNode;
  }
  
  export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
	const [customClaims, setCustomClaims] = useState<any | null>(null);
	const location = useLocation(); // Add this line to get the current location
	const [user, setUser] = useState<firebase.User | null>(null);
  
	useEffect(() => {
	  const fetchData = async () => {
		if (user) {
		  try {
			// Get the ID token
			const idTokenResult = await user.getIdTokenResult(true);
  
			// Access custom claims from the ID token result
			const userClaims = idTokenResult.claims;
  
			// Set custom claims in state
			setCustomClaims(userClaims);
		  } catch (error: any) {
			console.error('Error fetching custom claims:', error.message);
		  }
		}
	  };
  
	  if (user !== null) {
		fetchData();
	  }
	}, [user]);
  
	useEffect(() => {
	  const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
		setUser(authUser);
	  });
  
	  return () => unsubscribe();
	}, []);

	return (
	  <DataContext.Provider
		value={{customClaims }}
	  >
		{children}
	  </DataContext.Provider>
	);
  };
  
  // Define a custom hook to access the data
  export const useData = () => {
	const context = useContext(DataContext);
	if (context === undefined) {
	  throw new Error('useData must be used within a DataProvider');
	}
	return context;
  };
  