import React, { useEffect, useState } from 'react'
import { NavBar } from '../../common/nav/NavBar';
import { Header } from '../../common/header/Header';
import './organization.scss';
import { Link, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { testgooglefunctions } from '../../../services/OrganizationService'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import { useData } from '../../../context/DataContext'; // Import your DataContext

export const Organization = () => {
	
	const { id } = useParams<{ id: string }>();
	const [test, setTest] = useState<String>("");

	const { customClaims } = useData();


	const debouncedFetchTest = debounce(async () => {
		console.log(customClaims)
		try {
			const response = await testgooglefunctions(customClaims);
			if (!response.ok) {
				toast.error(await response.text(), {
					autoClose: 10000,
					draggable: true,
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			if (response.ok) {
				
				response.text().then((body) => setTest(body));
			}
		} catch (error) {
			console.error(`Error with fetching the teste: ${error}`);
		} finally {
		}
	}, 100);


	useEffect(() => {
		debouncedFetchTest();
		// Cleanup function
		return () => {
		  // Cancel any pending debounced calls during cleanup
		  debouncedFetchTest.cancel();
		};
	  }, []);

	return (
		<div className="container-left afterpop">
			< div className="row" >
				<div className="col-2 ">
					<NavBar />
				</div>
				<div className="col-8">
					<Header headertext={"New Organization"} />
					{id}
					{test}
				</div>
			</div >
		</div >
	);
};

export default Organization;

