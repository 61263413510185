import { baseUrlDev, baseUrlProd } from './Shared';

export async function testgooglefunctions(idToken: string) {
	return await fetch(
		`${baseUrlProd}/helloWorld`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
			},
		}
	);
}

export async function getOrganizations(idToken: string) {
	return await fetch(
		`${baseUrlProd}/getOrganizations`,
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${idToken}`, // Include the Firebase Authentication token in the Authorization header
			},
		}
	);
}