import React from 'react'; // Import the React module
import './NavBar.scss';
import { Link } from 'react-router-dom';

export function NavBar() {
	return (
		<div className="verticalnavbar">
			<div className="dashboardbtn btn">
				<Link
					to={'/dashboard'}
				>
					Dashboard
				</Link>
			</div>
			<div className="btnsGroup">
				<div className="btnsSummary">
					Managment
				</div>
				<div className="navbarBtn">
					<Link
						to={'/newOrganization'}
					>
						New Organization
					</Link>
				</div>
				<div className="navbarBtn">
					Organizations
				</div>
				<div className="navbarBtn">
					Users
				</div>
			</div>
			<div className="btnsGroup">
				<div className="btnsSummary">
					Communication
				</div>
				<div className="navbarBtn">
					Feedback
				</div>
				<div className="navbarBtn">
					Announcements
				</div>
			</div>
		</div>
	);
}