import React, { useState } from 'react'
import { NavBar } from '../../common/nav/NavBar';
import { Header } from '../../common/header/Header';
import './newOrganization.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import { Link, useNavigate } from 'react-router-dom';


export const NewOrganization = () => {
	const navigate = useNavigate();


	const [formData, setFormData] = useState({
		name: ""
	})

	const handleSubmit = (e: { preventDefault: () => void; }) => {
		e.preventDefault()
		navigate("/organization/"+formData.name);
	}

	return (
		<div className="container-left afterpop">
			< div className="row " >
				<div className="col-2 ">
					<NavBar />
				</div>
				<div className="col-8">
					<Header headertext={"New Organization"} />
					<div className="popup">
						<Link
							to={'/dashboard'}
						>
							<div className="close-container">

								<div className="leftright"></div>
								<div className="rightleft"></div>
							</div>
						</Link>
						<h2>Make a new organization</h2>
						<Form>
							<Form.Group className="mb-3" controlId="formBasic" onSubmit={handleSubmit}>
								<Form.Label>Organization Name:</Form.Label>
								<Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} placeholder="Enter name" />
								<Form.Text className="text-muted" 	>
									This can later be changed.
								</Form.Text>
							</Form.Group>
							<Button variant="primary" className="submit" type="submit" onClick={handleSubmit}>
								Submit
							</Button>
						</Form>
					</div>
				</div>
			</div >
		</div >
	);
}

export default NewOrganization;