import React, { useState } from 'react'
import debounce from 'lodash/debounce';
import { NavBar } from '../../common/nav/NavBar';
import { Header } from '../../common/header/Header';
import { ToastContainer, toast } from 'react-toastify';
import { testgooglefunctions } from '../../../services/OrganizationService';
import { logOut } from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';

export const Dashboard = () => {
	const navigate = useNavigate();
	const handleLogout = async () => {
		try {
		  logOut();
		  navigate('/');

		} catch (error) {
		  console.error('Error logging out:', error);
		}
	  };
	  
	return (
		<div className="container-left">
			< div className="row " >
				<div className="col-2 ">
					<NavBar />
				</div>
				<div className="col-8">
					<Header headertext={"Overview"} />
					<div className="container" style={{ height: "100%" }}>
						<div className="row align-items-start">
							<div className="col item" 
							onClick={handleLogout}>
								One of three columns
							</div>
							<div className="col item">
								One of three columns
							</div>
						</div>
						<div className="row align-items-end">
							<div className="col item">
								One of three columns
							</div>
							<div className="col item">
								One of three columns
							</div>
						</div>
					</div>
				</div>
			</div >
		</div >
	);
};

export default Dashboard;