import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useLocation } from 'react-router-dom';
import { DataProvider } from './context/DataContext';


import {
  Dashboard,
  Login,
  NewOrganization,
  Organization
} from './components/pages';

import './index.scss';



const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
		<BrowserRouter>
		<DataProvider>	
          <Routes>
              <Route
                path="/"
                element={
					<Login />
                }
              />
              <Route
                path="/dashboard"
                element={
					<Dashboard />
                }
              />
			  <Route
                path="/newOrganization"
                element={
					<NewOrganization />
                }
              />
			  <Route
				path='/organization/:id'
				element={
					<Organization />
				}
			  />
            </Routes>
			</DataProvider>
        </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
